<template>
  <header
    class="top-bar d-flex jc-space-between pl-28 pr-28"
    role="navigation"
    aria-label="Menu do topo da página">
    <div class="left-side mr-25">
      <template v-if="isUserLogged && !isTermsPage">
        <div
          tabindex="0"
          class="user-settings-menu"
          :class="{ active: view.openMenu }"
          @keypress.enter="toggleMenu">
          <button
            @click="toggleMenu"
            class="text-white h-100 d-flex ai-center user-menu pl-8 pr-8">
            <Avatar
              :src="imageUser"
              :alt="`Foto de ${identification.fantasyName}`" />
            <div
              class="username w-100 ml-15 text-left font-size-1 hide-xs"
              :title="identification.fantasyName">
              {{ identification.fantasyName }}
            </div>
            <i
              class="icon mdi mdi-chevron-down d-flex ai-center font-size-1-5"
              :class="{ rotate: view.openMenu }"></i>
          </button>

          <nav
            v-if="view.openMenu"
            class="settings-menu"
            role="navigation"
            v-click-outside="() => (view.openMenu = false)">
            <ul class="p-0 m-0">
              <li>
                <a
                  tabindex="0"
                  @click="goTo({ name: 'profile' })"
                  @keypress.enter="goTo({ name: 'profile' })"
                  :class="{
                    active: $route.name === 'profile',
                  }">
                  Perfil
                </a>
              </li>

              <li class="hide d-flex-md">
                <a
                  tabindex="0"
                  @click="goTo({ name: 'dashboard' })"
                  @keypress.enter="goTo({ name: 'dashboard' })"
                  :class="{
                    active: $route.name === 'dashboard',
                  }">
                  Indicadores
                </a>
              </li>

              <li class="hide d-flex-md">
                <a
                  tabindex="0"
                  @click="goTo({ name: 'events' })"
                  @keypress.enter="goTo({ name: 'events' })"
                  :class="{
                    active: $route.name === 'events',
                  }">
                  Demandas
                </a>
              </li>

              <li v-if="isAgency">
                <a
                  tabindex="0"
                  @click="goTo({ name: 'freelas' })"
                  @keypress.enter="goTo({ name: 'freelas' })"
                  :class="{
                    active: ['freelas', 'groups'].includes($route.name),
                  }">
                  Profissionais
                </a>
              </li>

              <li v-if="isAgency && !isTermsPage">
                <a
                  tabindex="0"
                  @click="goTo({ name: 'invoices' })"
                  @keypress.enter="goTo({ name: 'invoices' })"
                  :class="{
                    active: $route.name === 'invoices',
                  }">
                  Faturas
                </a>
              </li>

              <li v-if="isAgency && !isTermsPage">
                <a
                  tabindex="0"
                  @click="goTo({ name: 'payroll' })"
                  @keypress.enter="goTo({ name: 'payroll' })"
                  :class="{
                    active: $route.name === 'payroll',
                  }">
                  Folha de pagamento
                </a>
              </li>

              <li class="hide d-flex-sm">
                <Divider class="w-80" />
              </li>

              <li tabindex="0" @click="doLogout" @keypress.enter="doLogout">
                <a>
                  <i class="mdi mdi-logout-variant mr-5"></i>
                  <span>Sair da conta</span>
                </a>
              </li>

              <li class="hide d-flex-xs">
                <button
                  tabindex="0"
                  v-if="$route.name !== 'events.new' && !isTermsPage"
                  @click="
                    goTo({
                      name: 'events.new',
                      params: { step: 1 },
                    })
                  "
                  @keypress.enter="
                    goTo({
                      name: 'events.new',
                      params: { step: 1 },
                    })
                  "
                  class="new-event-button p-20">
                  Criar Demandas
                </button>
              </li>
            </ul>
          </nav>
        </div>

        <nav
          :class="{ 'jc-space-between': isAgency }"
          class="d-flex ai-center hide-md w-100">
          <a
            tabindex="0"
            @click="goTo({ name: 'dashboard' })"
            class="d-flex ai-center h-100"
            :class="{ active: $route.name === 'operations' }">
            Indicadores
          </a>

          <a
            tabindex="0"
            @click="goTo({ name: 'events' })"
            class="d-flex ai-center h-100"
            :class="{ active: $route.name === 'events' }">
            Demandas
          </a>

          <a
            tabindex="0"
            @click="goTo({ name: 'freelas' })"
            v-if="isAgency"
            class="d-flex ai-center h-100"
            :class="{
              active: ['freelas', 'groups'].includes($route.name),
            }">
            Profissionais
          </a>
        </nav>
      </template>

      <template v-else-if="isRegisterPage">
        <div class="h-100 d-flex ai-center">
          <router-link
            :to="{ name: 'access.login' }"
            class="bg-off text-malibu">
            Já tenho uma conta
          </router-link>

          <router-link
            v-if="queryParameterUserType"
            class="bg-off text-malibu"
            :to="{
              name: 'access.new-account',
              query: {
                userType:
                  queryParameterUserType === 'agency' ? 'hirer' : 'agency',
              },
            }">
            Sou
            {{
              queryParameterUserType === "agency"
                ? "um Contratante"
                : "uma Empresa"
            }}
          </router-link>
        </div>
      </template>

      <template v-else-if="isTermsPage">
        <router-link
          class="cursor-pointer tw-font"
          :class="{ active: this.$route.name === 'terms.terms' }"
          to="/termos/termos-de-uso">
          Termos de uso
        </router-link>
        <router-link
          class="cursor-pointer tw-font"
          :class="{ active: this.$route.name === 'terms.policies' }"
          to="/termos/politicas-de-privacidade">
          Politicas de privacidade
        </router-link>
      </template>
    </div>

    <div class="d-flex ai-center jc-center">
      <img class="w-100 h-50" alt="Logo da LanUp" src="@/assets/logo.svg" />
    </div>

    <div class="right-side d-flex jc-end ai-center ml-25">
      <div
        :class="isUserLogged && isAgency ? 'jc-space-between' : 'jc-end'"
        class="d-flex ai-center h-100 w-100 wrraper-btn">
        <template v-if="isUserLogged && isAgency && !isTermsPage">
          <div
            tabindex="0"
            class="finances-menu h-100"
            :class="{ active: view.openFinancesMenu }">
            <button
              @click="toggleFinancesMenu"
              class="text-white h-100 d-flex ai-center finances-menu-item">
              <div>Financeiro</div>
              <i
                class="icon mdi mdi-chevron-down d-flex ai-center font-size-1-5"
                :class="{ rotate: view.openFinancesMenu }"></i>
            </button>

            <nav
              v-if="view.openFinancesMenu"
              class="finances-nav"
              role="navigation"
              v-click-outside="() => (view.openFinancesMenu = false)">
              <ul class="p-0 m-0">
                <li>
                  <a
                    tabindex="0"
                    @click="goTo({ name: 'invoices' })"
                    class="d-flex ai-center h-100"
                    :class="{ active: $route.name === 'invoices' }">
                    Fatura
                  </a>
                </li>

                <li>
                  <a
                    tabindex="0"
                    @click="goTo({ name: 'payroll' })"
                    class="d-flex ai-center h-100"
                    :class="{ active: $route.name === 'payroll' }">
                    Folha de pagamento
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </template>

        <button
          v-if="isAgencyInactive && !isTermsPage"
          class="new-event-button radius-10 d-flex jc-center hide-xs"
          @click="openModalInactiveAlert">
          Criar Demandas
        </button>
        <button
          v-else-if="
            $route.name !== 'events.new' && !$route.name.includes('terms')
          "
          class="new-event-button radius-10 d-flex jc-center hide-xs"
          id="new-event-button"
          @click="goTo({ name: 'events.new', params: { step: 1 } })">
          Criar Demandas
        </button>
      </div>
    </div>
  </header>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { not } from "ramda";
import { isAgency, isAuthenticated } from "@/helpers/auth";
import { isProduction } from "@/helpers/env";

export default {
  data: () => ({
    view: {
      openMenu: false,
      openFinancesMenu: false,
    },
  }),
  computed: {
    ...mapGetters({
      avatarUrl: "User/avatarUrl",
      username: "User/username",
      isActiveAccount: "Profile/isActiveAccount",
      identification: "Profile/identification",
    }),
    isUserLogged() {
      return this.username;
    },
    queryParameterUserType() {
      return this.$route.query.userType;
    },
    imageUser() {
      return this.avatarUrl || require("@/assets/icon_avatar.svg");
    },
    isRegisterPage() {
      return this.$route.name === "access.new-account";
    },
    isTermsPage() {
      return (
        this.$route.name === "terms.terms" ||
        this.$route.name === "terms.policies"
      );
    },
    isAgency() {
      return isAgency();
    },
    isProduction() {
      return isProduction;
    },
    isAgencyInactive() {
      return isAuthenticated() && isAgency() && not(this.isActiveAccount);
    },
  },
  methods: {
    ...mapActions({
      loadAgencyData: "Profile/loadAgencyData",
    }),
    openModalInactiveAlert() {
      this.$eventhub.$emit("modal:open:inactiveAgencyAlert");
    },
    goTo(route) {
      this.$router.push(route);
      this.view.openMenu = false;
      this.view.openFinancesMenu = false;
    },
    doLogout() {
      this.$store.dispatch("User/logout");
      this.$router.push("/acesso/logout");
    },
    toggleMenu() {
      this.view.openMenu = !this.view.openMenu;
    },
    toggleFinancesMenu() {
      this.view.openFinancesMenu = !this.view.openFinancesMenu;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";
@import "@/styles/colors.scss";

.wrraper-btn {
  @media (max-width: 1278px) {
    justify-content: flex-end;
  }
}

.top-bar {
  background: $SteelGray;
  box-shadow: 0px 3px 6px rgba(black, 0.2);
  height: 80px;
  letter-spacing: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 9;

  .right-side,
  .left-side {
    display: flex;
    flex: 1;
    // max-width: calc(50% - 65px);
    // width: 100%;
  }

  .new-event-button {
    align-items: center;
    background: $Malibu;
    color: $SteelGray;
    display: inline-flex;
    font-size: 1.1rem;
    font-weight: bold;
    height: 70%;
    min-width: 250px;
    width: 40%;

    @media (max-width: $point-sm) {
      min-width: initial;
      width: 100%;
    }

    &:hover,
    &:active {
      background: lighten($Malibu, 10);
    }
  }

  a {
    color: white;
    cursor: pointer;
    display: inline-flex;
    padding: 10px;

    &.active {
      background: $FuchsiaBlue;
      color: white;
      text-decoration: none;
    }

    &:hover,
    &:active {
      background: lighten($FuchsiaBlue, 5);
      color: white;
      text-decoration: none;
    }
  }

  .user-settings-menu {
    min-width: 230px;
    will-change: background;

    &.active {
      background: $SteelGray;
    }

    .user-menu {
      min-width: 230px;
      box-shadow: 0px 3px 6px rgba(black, 0.2);

      @media (max-width: $point-xs) {
        min-width: initial;
      }

      &:hover,
      &:active {
        background: rgba(black, 0.2);
      }

      .avatar {
        height: auto;
        object-fit: contain;
        width: 54px;
      }

      .icon {
        transition: transform 0.3s ease;
        will-change: transform;
        margin-right: -3px;

        &.rotate {
          transform: rotate(180deg);
        }
      }

      .username {
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .settings-menu {
      background: inherit;
      box-shadow: 1px 4px 5px 0px rgba(black, 0.2);

      li {
        list-style: none;

        .divider {
          margin: 0 auto;
        }

        .new-event-button {
          border-radius: 0;
          width: 100%;
          font-size: 1rem;
        }

        a {
          width: 100%;
        }
      }
    }
  }

  .finances-menu {
    min-width: 180px;
    will-change: background;

    &.active {
      background: $SteelGray;
    }

    .finances-menu-item {
      box-shadow: 0px 3px 6px rgba(black, 0.2);
      border-radius: 0;

      @media (max-width: $point-xs) {
        min-width: initial;
      }

      &:hover,
      &:active {
        background: lighten($FuchsiaBlue, 5);
      }
    }

    .finances-nav {
      background: inherit;
      box-shadow: 1px 4px 5px 0px rgba(black, 0.2);

      li {
        list-style: none;

        .divider {
          margin: 0 auto;
        }

        a {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .tw-font {
    font-size: 10px;
  }
}
@media (max-width: 540) {
  .tw-font {
    font-size: 3px;
  }
}
</style>
