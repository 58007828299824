<template>
  <PageLayout>
    <template slot="header">
      <TopBar />
    </template>

    <template slot="content">
      <div class="p-20 d-flex jc-center">
        <md-content class="md-scrollbar">
          <router-view />
        </md-content>
      </div>
    </template>
  </PageLayout>
</template>

<script>
import TopBar from "@/components/main/TopBar.vue";

export default {
  components: { TopBar },
};
</script>
